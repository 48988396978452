import produce from "immer";
// import pages from './pages';
// import activeElements from './activeElements';
import {
    applyChangesToElements,
    applyChangeToElement,
    assignHashToObj,
    elementsBoundingBox,
    findGroupChildElements,
    findGroupElementByChildElement,
    getIdxByHash,
    getObjByHash,
    getPageIndexByElementHash,
    resizePage,
    resolveElementsIndexInfo,
    resolveGroupElement
} from '../utilities/canvasData';

import {getBoundingBox} from "../math/frame";

import demo1 from '../demoData/demo1';
import checkoutReducer from './checkoutReducer'
import tabReducer from './tabReducer'
import initialState from '../demoData/init'
import {getSnapInfo} from '../utilities/snap'
import featuredDesign from '../demoData/featuredDesigndemo'
import savedDesign from '../demoData/savedDesigndemo'

const app = (state = {
    pages: initialState,
    activeElements: [],
    activeElementHashs: [],
    zoomScale: 1,
    activePageIndex: 0,
    histories: [],
    currentHistory: -1,
    lastSaveId: 0,
    currentSaveId: 0,
    focusPageId: 0,
    featured_designs: featuredDesign,
    saved_designs: savedDesign,
    itemsVisibility: {},
    uploadedItemsVisibility: {},
    access_level: 0,
    username: 0,
    isShowingPrintBleed: false,
    isShowingMargin: false,
    permission: "read",
    engineReady: false,
    productName: "",
    productList: [],
    myFavouriteFilter: false,
    has_watermark: true,
    callback_url: null,
    main_url: null,
    save_url: null,
    partnership_link: "",
    partnership_param: "",
    random: 0,
    recentlyUsedImages: [],
    recentlyUsedPhotos: [],
    currentProjectOptions: {},
    editTextElement: null,
    proxyHoverElementHash: null,
    is_SideBar: true,
    hash: '126' // for getDesignElement caching
}, action) => {
    const printBleedPadding = 1.5 / 25.4 * 300; // 1.5mm
    const saveHistory = data => {
        var histories = state.histories.slice(0, state.currentHistory + 1);
        var combinedData = {pages: data, currentProjectOptions: state.currentProjectOptions};
        var newData = JSON.stringify(combinedData);
        var oldData = JSON.stringify(histories[histories.length - 1]);
        if (newData !== oldData) {
            histories.push(JSON.parse(newData));
            return {histories, currentHistory: histories.length - 1, currentSaveId: state.currentSaveId + 1};
        } else {
            return {};
        }
    };

    const produceActiveElements = (pages, activeElementHashs) => {
        if (!activeElementHashs) {
            return {activeElementHashs: [], activeElements:[]};
        }
        //var histories = state.histories.slice(0, state.currentHistory + 1);
        var newActiveElementData = JSON.stringify(activeElementHashs);
        var oldActiveElementData = JSON.stringify(state.activeElementHashs);
        if (newActiveElementData !== oldActiveElementData) {
            if (!Array.isArray(activeElementHashs) && console.error) {
                console.error('activeElementHashs not an array', activeElementHashs, state.activeElementHashs);
            }
            return {
                activeElementHashs: activeElementHashs,
                activeElements: activeElementHashs.map(activeElementHash => getObjByHash(activeElementHash, pages)).filter(activeElementHash => activeElementHash)
            };
        }
        var newPageData = JSON.stringify(pages);
        var oldPageData = JSON.stringify(state.pages);
        if (newPageData !== oldPageData) {
            return {
                activeElementHashs: activeElementHashs,
                activeElements: activeElementHashs.map(activeElementHash => getObjByHash(activeElementHash, pages)).filter(activeElementHash => activeElementHash)
            };
        }
        return {activeElementHashs: [], activeElements:[]};
    };

    state = produce(state, state => {
        state.checkout = checkoutReducer(state.checkout, action);
        state.tab = tabReducer(state.tab, action);
    });

    const addElementByData = (data, pages) => {
        if (data.type === 'layout') {
            action.point = null;
        }
        if (data.element) {
            data = {
                type: data.type,
                pages: [{
                    width: data.width ? data.width : 336,
                    height: data.height ? data.height : 336,
                    elements: [data.element]
                }]
            };
        } else if (data.elements) {
            data = {
                type: data.type,
                pages: [{
                    width: 336,
                    height: 336,
                    elements: data.elements
                }]
            }
        } else if (!data.pages) {
            console.log('Invalid data');
            return {...state};
        }

        var newActiveElements = [];
        // var pages = JSON.parse(JSON.stringify(state.pages));
        if (pages.length === 0) {
            return {...state}
        }
        data.pages.forEach((page, index) => {
            var elements = page.elements;
            if (data.type === 'text') {
                page.elements = elements = elements.filter(element => element && element.type !== 'background')
            }

            var reassignMap = {};
            assignHashToObj(page, true, reassignMap);
            page.elements = page.elements.map(element => {
                if (element.type === 'group') {
                    element.elements = element.elements.map(elementHash => {
                        if (reassignMap[elementHash]) {
                            return reassignMap[elementHash];
                        } else {
                            return elementHash;
                        }
                    })
                }
                return element;
            });
            if (page.elements.length && page.elements[0].type === 'background' && page.elements[0].photoUrl && data.type === 'background') {
                page.width = page.elements[0].imageWidth;
                page.height = page.elements[0].imageHeight;
            }

            page = resizePage(page, state.pages[0].width, state.pages[0].height);
            elements = page.elements;
            var point = action.point;
            var targetPage = action.page;

            if (targetPage === undefined) {
                targetPage = state.focusPageId;
            }
            if (data.pages.length > 1) {
                targetPage = index;
            }
            if (data.type === 'layout') {
                if (pages[targetPage]) {
                    pages[targetPage].elements = [];
                }
            }
            var boundingBox = null;
            if (data.type === 'layout') {
                boundingBox = elementsBoundingBox(elements, page.width, page.height);
            } else {
                boundingBox = elementsBoundingBox(elements);
            }

            elements = elements.map(element => {
                element.centerX -= (boundingBox.left + boundingBox.width / 2);
                element.centerY -= (boundingBox.top + boundingBox.height / 2);
                return element;
            });
            var {centerX, centerY} = [boundingBox.centerX, boundingBox.centerY];
            newActiveElements = [];

            if (!pages[targetPage]) {
                pages[targetPage] = {
                    width: pages[0].width,
                    height: pages[0].height,
                    elements: []
                };
                assignHashToObj(pages[targetPage])
            }
            var activeElements = state.activeElements;
            var activeElementPage = null;
            if (activeElements && activeElements.length) {
                activeElementPage = getPageIndexByElementHash(pages, activeElements[0].hash);
            }

            elements.forEach(element => {
                if (element.type === 'background') {
                    centerX = pages[targetPage].width / 2;
                    centerY = pages[targetPage].height / 2;
                } else if (action.point) {
                    centerX = element.centerX + point.centerX;
                    centerY = element.centerY + point.centerY;
                } else if (state.activeElements && state.activeElements.length && activeElementPage === targetPage) {
                    var activeElementBoundingBox = elementsBoundingBox(activeElements);
                    var xOffset = state.pages[0].width * 0.05;
                    var yOffset = state.pages[0].height * 0.05;
                    centerX = element.centerX + activeElementBoundingBox.centerX + xOffset;
                    centerY = element.centerY + activeElementBoundingBox.centerY + yOffset;
                } else {
                    centerX = element.centerX + pages[targetPage].width / 2;
                    centerY = element.centerY + pages[targetPage].height / 2;
                }
                element.centerX = centerX;
                element.centerY = centerY;
                element = assignHashToObj(element);
                newActiveElements.push(element.hash);
                if (element.type === 'background') {
                    pages[targetPage].elements = pages[targetPage].elements.filter(element => element && element.type !== 'background');
                    pages[targetPage].elements.unshift(element);
                } else {
                    pages[targetPage].elements.push(element);
                }
            })
        });
        if (data.type === 'layout') {
            newActiveElements = [];
        }
        return {pages, ...produceActiveElements(pages, newActiveElements)}
    };
    switch (action.type) {
        case 'LOAD_DEFAULT':
            var data = demo1;
            data = assignHashToObj(data);
            return {...state, pages: data, ...saveHistory(data)};
        case 'LOAD_CANVAS_DATA':
            var data = action.data;
            data = assignHashToObj(data);
            if (data && data.length) {
                data.forEach(page => {
                    if (!page.elements) {
                        page.elements = [];
                    }
                    if (typeof page.elements === 'object') {
                        page.elements = Object.values(page.elements);
                    }
                    var background = page.elements.find(element => element.type === 'background');
                    if (!background) {
                        var background = {
                            type: 'background',
                            width: page.width + printBleedPadding * 2,
                            height: page.height + printBleedPadding * 2,
                            centerX: page.width / 2,
                            centerY: page.height / 2,
                            imageWidth: page.width,
                            imageHeight: page.height,
                            imageOriginLeft: 0,
                            imageOriginTop: 0,
                            rotation: 0,
                        };

                        background = assignHashToObj(background);
                        page.elements.unshift(background);
                    }
                })
            }

            return {...state, pages: data, ...saveHistory(data), applyFromUserChanges: false};
        case 'ADD_NEW_PAGE':
            var data = action.data;
            var default_data = {
                width: state.pages[0].width,
                height: state.pages[0].height,
                elements: []
            };
            var background = state.pages[state.pages.length - 1].elements.find(element => element && element.type === 'background');
            // default_data.backgroundColor = "#fff";
            if (background) {
                default_data.elements = default_data.elements.filter(element => element && element.type !== 'background');
                default_data.elements.unshift(JSON.parse(JSON.stringify(background)));
            }
            default_data = assignHashToObj(default_data, true);
            data.push(default_data);

            return {...state, pages: data, ...saveHistory(data)};
        case 'DELETE_PAGE':
            var data = action.data;
            const idx = action.idx;
            var activePageIndex = idx;
            if (data.length > 1) {
                data.splice(idx, 1);
            }
            if (idx > state.pages.length - 1) {
                activePageIndex = state.pages.length - 1;
            } else {
                activePageIndex = idx;
            }
            data = assignHashToObj(data);
            return {
                ...state,
                pages: data,
                activePageIndex: activePageIndex, ...produceActiveElements(data, state.activeElementHashs), ...saveHistory(data)
            };
        case 'DELETE_ELEMENT':
            var activeElements = state.activeElements;
            if (activeElements.length === 1 && activeElements[0].type === 'background') {
                var pages = JSON.parse(JSON.stringify(state.pages));
                var backgroundElement = getObjByHash(activeElements[0].hash, pages);
                // backgroundElement.opacity = 1;
                backgroundElement.backgroundColor = null;
                backgroundElement.photoUrl = null;
                return {
                    ...state,
                    pages: pages, ...produceActiveElements(pages, state.activeElementHashs), ...saveHistory(pages)
                };
            } else {
                let new_data = [], item;
                state.pages.map(function (page) {
                    item = {
                        height: page.height,
                        width: page.width,
                        hash: page.hash,
                        elements: []
                    };
                    const el1 = page.elements.filter(el => !state.activeElementHashs.includes(el.hash));
                    item.elements = el1;
                    new_data.push(item);
                });
                return {...state, pages: new_data, ...produceActiveElements(new_data, []), ...saveHistory(new_data)};
            }

        case 'SET_ACTIVE_ELEMENT_BY_HASH':
            var pages = JSON.parse(JSON.stringify(state.pages));
            pages.forEach(page => {
                page.elements.forEach(element => {
                    if (element.tempTextContent || element.tempTextContent === '') {
                        element.textContent = element.tempTextContent;
                        delete element['tempTextContent'];
                    }
                })
            });
            var elementHashs = action.elementHashs.filter(hash => getObjByHash(hash, state.pages));
            var activeElements = elementHashs.map(hash => getObjByHash(hash, state.pages));
            if (!activeElements[0]) {
                if(elementHashs.length && action.elementHashs.length)
                {
                    console.error('fail to get active element', action.elementHashs[0], action.elementHashs, state.pages);
                }
                return {...state};
            }
            var groupElements = activeElements.filter(element => element && element.type === 'group');
            groupElements.forEach(groupElement => {
                elementHashs = elementHashs.concat(groupElement.elements);
            });
            elementHashs = elementHashs.filter((v, i, a) => a.indexOf(v) === i);

            var showimgList = state.showImageFilter;
            var filterMode = state.filterMode;
            if ((activeElements[0].type === "photo" || activeElements[0].type === "graphic") && showimgList && showimgList.listname !== "colorPicker" && showimgList && showimgList.listname ||
                activeElements[0].type === "text" && showimgList && showimgList.listname === "colorPicker") {
                showimgList = state.showImageFilter;
                filterMode = showimgList.listname;
                var color;
                if (activeElements[0].type === "text" && showimgList.listname === "colorPicker") {
                    color = activeElements[0].textColor;
                }
            } else {
                showimgList = "";
                filterMode = 'default'
            }
            var lastFocusTextHash = state.lastFocusTextHash;
            elementHashs.forEach(hash => {
                let element = getObjByHash(hash, pages);
                if (element && element.type === 'text' && element.hash) {
                    lastFocusTextHash = element.hash;
                }
            });
            return {
                ...state,
                lastFocusTextHash,
                pages: pages,
                showlist: "",
                filterMode: filterMode,
                showImageFilter: showimgList,
                currentColor: color,
                itemsVisibility: {},
                uploadedItemsVisibility: {}, ...produceActiveElements(pages, elementHashs)
            };
        case 'CLEAR_ACTIVE_ELEMENT':
            var pages = JSON.parse(JSON.stringify(state.pages));
            pages.forEach(page => {
                if (page.elements && Array.isArray(page.elements)) {
                    page.elements.forEach(element => {
                        if (element.tempTextContent || element.tempTextContent === '') {
                            element.textContent = element.tempTextContent;
                            delete element['tempTextContent'];
                        }
                    });
                }
            });
            return {
                ...state,
                pages: pages,
                showlist: "",
                editTextElement: null,
                showImageFilter: "", ...produceActiveElements(pages, []), ...saveHistory(state.pages)
            };
        case 'SET_ACTIVE_ELEMENT_PROPERTY':
            var changes = action.changes;
            var pages = produce(state.pages, pages => {
                var activeElements = state.activeElementHashs;
                if (activeElements) {
                    var groupElement = resolveGroupElement(state.activeElements);
                    if (groupElement) {
                        applyChangeToElement(groupElement.hash, pages, changes);
                    } else {
                        activeElements.forEach(activeElementHash => {
                            applyChangeToElement(activeElementHash, pages, changes);
                        })
                    }
                }
            });
            return {...state, pages: pages, showlist: "", ...produceActiveElements(pages, state.activeElementHashs), applyFromUserChanges: action.applyFromUserChanges?action.applyFromUserChanges:state.applyFromUserChanges};
        case 'SET_PRESET_DESIGN_OPTION':
            var option = action.option;

            var json = (option.json && 'string' === typeof option.json) ? JSON.parse(option.json ? option.json : null) : option.json;
            if (!option.json) {
                return {...state}
            }
            let JsonLength = 0;
            if (!option.json || !json) {
                return {...state}
            }
            if (Array.isArray(json)) {
                JsonLength = json.length;
            } else {
                JsonLength = Object.keys(json).length;
            }
            var pages = JSON.parse(JSON.stringify(state.pages));
            var elementHashes = [];

            if (json[0]) {
                // Looking for elementHashes
                let found = 0;
                pages.forEach(page => {
                    page.elements.forEach(element => {
                        if (('object' === typeof element)
                            && found < JsonLength
                            && found + 1 >= Math.abs(option.position)
                            && (element.type === json[found].type)
                        ) {
                            ++found;
                            elementHashes.push(element.hash);
                        }
                    })
                });
            } else {
                elementHashes = Object.keys(json);
            }

            if (elementHashes.length !== JsonLength) {
                elementHashes = [];
            }

            // console.log('check valid selection', pages, elementHashes, option, option.position, JsonLength, json[0]);
            if (!elementHashes.length && option.position > 0) {
                // invalid json
                return {...state};
            }

            var preset = state.projectOptions ? state.projectOptions.preset : [];

            // set flag selected for the preset option
            if (preset && preset[action.label]) {
                for (let index in preset[action.label]) {
                    var opt = preset[action.label][index];

                    var jsonPreset = ('string' === typeof opt.json) ? JSON.parse(opt.json ? opt.json : null) : opt.json;
                    if (opt.json && Array.isArray(jsonPreset)) {
                        //check hashing
                        var newJson = {};
                        for (let index in elementHashes) {
                            newJson[elementHashes[index]] = jsonPreset[index];
                        }
                        opt.json = newJson;
                    }

                    if (opt.id === option.id) {
                        opt.selected = true;
                    } else {
                        delete opt.selected;
                    }
                }
            }
            //console.log('valid selection', elementHashes, option.position, state.projectOptions.preset, option.id);

            if (elementHashes.length) { // replacement
                var pages = state.pages;

                var customTexts = {};
                if (state.customTexts) {
                    customTexts = JSON.parse(JSON.stringify(state.customTexts));
                }

                if (!Array.isArray(json)) {
                    pages = produce(pages, pages => {
                        applyChangesToElements(pages, json);
                    });
                    customTexts = {...customTexts, ...json};
                } else {
                    for (let index in elementHashes) {
                        let elementHash = elementHashes[index];
                        let changes = json[index] ? json[index] : json[elementHash];
                        //console.log('applying json changes', changes, json);

                        pages = produce(pages, pages => {
                            applyChangeToElement(elementHash, pages, changes);
                        });

                        if (customTexts[elementHash]) {
                            customTexts[elementHash] = {...customTexts[elementHash], ...changes};
                        } else {
                            customTexts[elementHash] = changes;
                        }
                    }
                }


                return {...state, customTexts, pages: pages, ...produceActiveElements(pages, state.activeElementHashs)}
            } else {
                // goto add_element
                // console.log({option});
                // console.log('insert new element')
                return {...state};
            }
        case 'SET_ELEMENT_PROPERTY':
            var changes = action.changes;
            var elementHash = action.elementHash;

            if (changes['textContent']) {
                obj = getObjByHash(elementHash, state.pages);
                if (obj && obj['maxChar']) {
                    changes['textContent'] = changes['textContent'].substr(0, obj['maxChar']);
                }
            }

            var pages = produce(state.pages, pages => {
                applyChangeToElement(elementHash, pages, changes);
            });
            //console.log('state changed')
            return {...state, pages: pages, ...produceActiveElements(pages, state.activeElementHashs), applyFromUserChanges: action.applyFromUserChanges?action.applyFromUserChanges:state.applyFromUserChanges};
        case 'SET_GROUPPED_ELEMENT_PROPERTY':
            var grouppedChanges = action.grouppedChanges;
            var pages = produce(state.pages, pages => {
                for (let elementHash in grouppedChanges) {
                    let changes = grouppedChanges[elementHash];
                    applyChangeToElement(elementHash, pages, changes);
                }
            });
            return {...state, pages: pages, ...produceActiveElements(pages, state.activeElementHashs)};
        case 'ADD_ELEMENT_BY_DATA':
            try {
                var data = JSON.parse(action.data);
                var newData = addElementByData(data, JSON.parse(JSON.stringify(state.pages)));
                return {
                    ...state,
                    pages: newData.pages, ...produceActiveElements(newData.pages, newData.activeElementHashs), ...saveHistory(newData.pages)
                }
            } catch (e) {
                return {...state};
            }

        case 'SET_PROJECT_SAVING':
            var isSaving = action.isSaving;
            return {...state, isSaving: isSaving};
        case 'SET_FILTER_MODE':
            return {...state, editMode: 'transform', filterMode: 'filter'};
        case 'SET_ADJUST_MODE':
            return {...state, editMode: 'transform', filterMode: 'adjust'};
        case 'SET_CROP_MODE':
            return {...state, editMode: 'crop', filterMode: 'default'};
        case 'SET_COLOR_PICKER_MODE':
            return {...state, editMode: 'transform', filterMode: 'colorPicker'};
        case 'SET_DEFAULT_MODE':
            return {...state, editMode: 'default', filterMode: 'default'};
        case 'SET_TRANSFORM_MODE':
            return {...state, editMode: 'transform', filterMode: 'default'};
        case 'SET_TEXT_EDIT_MODE':
            return {...state, editMode: 'text'};
        case 'SET_MY_FAVOURITE_FILTER':

            return {...state, myFavouriteFilter: !state.myFavouriteFilter};
        case 'SET_SOLID_COLOR_PICKER_MODE':
            return {...state, editMode: 'transform', filterMode: 'solidColorPicker'};
        case 'SET_IMAGE_CROP_DATA':
            var data = JSON.parse(JSON.stringify(action.data));
            return {...state, imageCropData: data};
        case 'SET_ACTIVE_ELEMENT_INDEX':
            var activeElements = state.activeElementHashs;
            if(!activeElements.length) {
                return {...state};
            }
            var result = getIdxByHash(activeElements[0], state.pages);
            var page_id = result.pageIdx;
            var el_idx = result.elementIdx;
            if(!state.pages[page_id]) {
                console.error('page_id not found', activeElements[0], state.activeElementHashs, result);
                return {...state};
            }
            var len = state.pages[page_id].elements? state.pages[page_id].elements.length: 0;
            return {...state, pageIdx: page_id, elementIdx: el_idx, elLength: len};
        case 'REORDER_ARRAY':
            var pages = JSON.parse(JSON.stringify(state.pages));
            var direction = null;
            if (action.move.position === 'forward' || action.move.position === 'to_front') {
                direction = 'forward';
            } else if (action.move.position === 'backward' || action.move.position === 'to_back') {
                direction = 'backward';
            }
            if (!state.activeElements || !state.activeElements.length) return state;
            var pageId = getPageIndexByElementHash(pages, state.activeElementHashs[0]);
            var pageElements = pages[pageId].elements;
            var activeElements = state.activeElements;
            var indexInfo = resolveElementsIndexInfo(pageElements, activeElements);
            var targetIndex = -1;
            if (direction === 'backward') {
                if (indexInfo.minIndex === 0) {
                    targetIndex = 0;
                } else {
                    targetIndex = indexInfo.minIndex - 1;
                }
            } else if (direction === 'forward') {
                targetIndex = indexInfo.maxIndex + 1 - state.activeElements.length;
            }
            pageElements = pageElements.filter(element => !state.activeElementHashs.includes(element.hash));
            if (pageElements[targetIndex]) {
                var groupElement = null;
                if (pageElements[targetIndex].type === 'group') {
                    groupElement = pageElements[targetIndex];
                }
                if (!groupElement) {
                    groupElement = findGroupElementByChildElement(pageElements, pageElements[targetIndex]);
                }
                if (groupElement) {
                    var indexInfo = resolveElementsIndexInfo(pageElements, groupElement.elements.map(elementHash => getObjByHash(elementHash, pages)).concat([groupElement]));
                    if (direction === 'backward') {
                        if (indexInfo.minIndex === 0) {
                            targetIndex = 0;
                        } else {
                            targetIndex = indexInfo.minIndex;
                        }
                    } else if (direction === 'forward') {
                        targetIndex = indexInfo.maxIndex + 1;
                    }
                } else {
                    if (direction === 'forward') {
                        targetIndex = targetIndex + 1;
                    }
                }
            }
            if (action.move.position === 'to_back') {
                if (pageElements[0].type === 'background') {
                    targetIndex = 1;
                } else {
                    targetIndex = 0;
                }
            }
            if (action.move.position === 'to_front') {
                targetIndex = pageElements.length;
            }
            activeElements.forEach(activeElement => {
                pageElements.splice(targetIndex, 0, activeElement);
            });

            pages[pageId].elements = pageElements;
            return {
                ...state,
                pages: pages,
                pageIdx: page_id, ...produceActiveElements(pages, state.activeElementHashs)
            };
        case 'UPDATE_SNAP_INFO':
            let snapInfo = getSnapInfo(state.pages, Math.min(state.pages[0].width, state.pages[0].height) * 0.1);
            return {...state, snapInfo: snapInfo};
        case 'COPY_ELEMENT':
            var activeElements = [];
            let newElements = [];
            var pages = JSON.parse(JSON.stringify(state.pages));
            var groupElement = resolveGroupElement(state.activeElements);
            let page = pages.find(page => page.elements.find(element => state.activeElementHashs[0] === element.hash));
            let elements = null;
            if (groupElement) {
                elements = findGroupChildElements(page.elements, groupElement.hash);
            } else {
                elements = [page.elements.find(element => element.hash === state.activeElementHashs[0])];
            }

            elements.forEach(element => {
                let newElement = {
                    ...element,
                    centerX: element.centerX + 10,
                    centerY: element.centerY + 10,
                    hash: null
                };
                newElement = assignHashToObj(newElement);
                newElements.push(newElement);
                activeElements.push(newElement.hash);
            });
            page.elements = page.elements.concat(newElements);

            if (groupElement) {
                let newGroupElement = {
                    ...groupElement,
                    centerX: groupElement.centerX + 10,
                    centerY: groupElement.centerY + 10,
                    elements: newElements.map(element => element.hash),
                    hash: null
                };
                newGroupElement = assignHashToObj(newGroupElement);
                activeElements.push(newGroupElement.hash);
                page.elements = page.elements.concat([newGroupElement]);
            }
            return {...state, pages: pages, ...produceActiveElements(pages, activeElements), ...saveHistory(pages)};
        case 'REPLACE_FOCUS_PAGE_BACKGROUND_COLOR':
            var color = action.color;
            var pages = JSON.parse(JSON.stringify(state.pages));
            var focusPage = pages[state.focusPageId];
            var backgroundElement = {
                centerX: focusPage.width / 2,
                centerY: focusPage.height / 2,
                width: focusPage.width,
                height: focusPage.height,
                rotation: 0,
                opacity: 0.8,
                type: "background",
                backgroundColor: color,
                imageWidth: focusPage.width,
                imageHeight: focusPage.height,
                imageOriginLeft: 0,
                imageOriginTop: 0,
                //photo specific attr
                photoUrl: null,
            };
            assignHashToObj(backgroundElement);
            focusPage.elements = focusPage.elements.filter(element => element && element.type !== 'background');

            focusPage.elements.unshift(backgroundElement);
            return {...state, pages: pages, ...produceActiveElements(pages, [backgroundElement.hash])};
        case 'SET_ACTIVE_PAGE_PROPERTY':
            var changes = action.changes;
            var pages = produce(state.pages, pages => {
                var activePage = pages[state.activePageIndex];

                if (activePage) {
                    for (var changeProp in changes) {
                        if (typeof state.pageIdx !== 'undefined') {
                            pages[state.pageIdx].backgroundColor = changes[changeProp];
                        }

                        activePage.backgroundColor = changes[changeProp];
                    }
                    return {
                        ...state,
                        pages: pages,
                        activePage: activePage, ...produceActiveElements(pages, state.activeElementHashs)
                    }
                }
            });
            var activePage = pages[state.activePageIndex];

            if (activePage) {
                return {
                    ...state,
                    pages: pages,
                    activePage: activePage, ...produceActiveElements(pages, state.activeElementHashs)
                }
            } else {
                return state;
            }
        case 'SHOW_FONT_LIST':
            var list = state.showlist;
            var name = action.listname;
            var is_display = false;

            if (list) {
                if (action.listname === list.listname) {
                    if (!list.is_display) {
                        is_display = true;
                    } else {
                        is_display = false;
                        name = "";
                    }
                } else {
                    if (!list.is_display) {
                        is_display = true;
                    } else {
                        is_display = false;
                    }
                }
            }

            var show = {
                listname: name,
                is_display: is_display
            };
            return {...state, showlist: show, itemsVisibility: {}, uploadedItemsVisibility: {}};
        case 'CREATE_VIRTUAL_GROUP':
            if (!action.hashs) {
                return state;
            }
            var hashs = action.hashs.filter(hash => getObjByHash(hash, state.pages));
            if (!hashs || !hashs.length) {
                return state;
            }
            var elementList = hashs.map(hash => getObjByHash(hash, state.pages));
            var activePageIdxs = elementList.map(element => getPageIndexByElementHash(state.pages, element.hash)).filter((v, i, a) => a.indexOf(v) === i);
            if (activePageIdxs.length > 1) {
                return state;
            }
            var activePageIdx = activePageIdxs[0];
            var groupElement = null;
            var pages = produce(state.pages, pages => {
                var top = null;
                var left = null;
                var bottom = null;
                var right = null;
                elementList.forEach(element => {
                    var boundingBox = getBoundingBox(element.centerX - element.width / 2, element.centerY - element.height / 2, element.width, element.height, element.rotation);
                    top = (top === null) ? boundingBox.y : Math.min(top, boundingBox.y);
                    left = (left === null) ? boundingBox.x : Math.min(left, boundingBox.x);
                    bottom = (bottom === null) ? (boundingBox.y + boundingBox.height) : Math.max(bottom, boundingBox.y + boundingBox.height);
                    right = (right === null) ? (boundingBox.x + boundingBox.width) : Math.max(right, boundingBox.x + boundingBox.width);
                });
                groupElement = {
                    type: 'group',
                    centerX: left + (right - left) / 2,
                    centerY: top + (bottom - top) / 2,
                    width: right - left,
                    height: bottom - top,
                    rotation: 0,
                    isVirtual: true,
                    elements: elementList.map(element => element.hash),
                };
                groupElement = assignHashToObj(groupElement);
                let maxIndex = -1;
                pages[activePageIdx].elements.forEach((pageElement, index) => {
                    if (hashs.includes(pageElement.hash)) {
                        if (maxIndex < index) {
                            maxIndex = index;
                        }
                    }
                });
                pages[activePageIdx].elements.splice(maxIndex, 0, groupElement)
            });

            // pages[activePageIdx].elements.push(groupElement);
            var activeElements = produce(state.activeElementHashs, activeElements => {
                activeElements.push(groupElement.hash);
            });
            return {...state, pages: pages, ...produceActiveElements(pages, activeElements)};
        case 'UNGROUP_VIRTUAL_GROUP':
            var pages = produce(state.pages, pages => {
                pages = pages.map(page => {
                    page.elements.forEach(element => {
                        if (element.type === 'group' && element.isVirtual && typeof element.opacity !== 'undefined') {
                            element.elements.forEach(childElementHash => {
                                const childElement = getObjByHash(childElementHash, pages);
                                applyChangeToElement(childElementHash, pages, {
                                    opacity: element.opacity * (typeof childElement.opacity === 'undefined' ? 1 : childElement.opacity)
                                })
                            })
                        }
                    });
                    page.elements = page.elements.filter(element => {
                        return element.type !== 'group' || !element.isVirtual;
                    });
                    return page;
                })
            });
            var activeElements = state.activeElementHashs.filter(hash => getObjByHash(hash, pages));
            return {...state, pages: pages, ...produceActiveElements(pages, activeElements)};
        case 'TOGGLE_ACTUAL_GROUP':
            var hash = action.hash;
            var pages = JSON.parse(JSON.stringify(state.pages));
            if (action.isActual) {
                var pageId = getPageIndexByElementHash(pages, hash);
                var pageElements = pages[pageId].elements;
                var groupElement = getObjByHash(hash, pages);
                var groupChildElements = groupElement.elements.map(elementHash => getObjByHash(elementHash, pages));
                pageElements = pageElements.filter(element => !groupElement.elements.includes(element.hash));
                var newGroupIndex = pageElements.findIndex(pageElement => pageElement.hash === groupElement.hash);
                groupChildElements.reverse().forEach(childElement => {
                    pageElements.splice(newGroupIndex, 0, childElement);
                });
                pages[pageId].elements = pageElements;
            }
            var changes = {
                isVirtual: !action.isActual
            };
            var obj = applyChangeToElement(hash, pages, changes);
            return {
                ...state,
                pages: pages, ...produceActiveElements(pages, state.activeElementHashs), ...saveHistory(pages)
            };
        case 'ALIGN_ELEMENT_TO_PAGE':
            var alignment = action.alignment;
            if (!state.activeElements || !state.activeElements.length) return state;
            var pages = JSON.parse(JSON.stringify(state.pages));
            var activeElements = JSON.parse(JSON.stringify(state.activeElements));
            var groupElement = resolveGroupElement(activeElements);
            var referenceFrame = null;
            var objectFrame = null;
            if (groupElement && groupElement.isVirtual) {
                referenceFrame = getBoundingBox(groupElement.centerX - groupElement.width / 2, groupElement.centerY - groupElement.height / 2, groupElement.width, groupElement.height, groupElement.rotation);
            } else {
                referenceFrame = {
                    x: 0,
                    y: 0,
                    width: state.pages[state.focusPageId].width,
                    height: state.pages[state.focusPageId].height,
                }
            }
            if (groupElement && !groupElement.isVirtual) {
                objectFrame = getBoundingBox(groupElement.centerX - groupElement.width / 2, groupElement.centerY - groupElement.height / 2, groupElement.width, groupElement.height, groupElement.rotation);
            }
            pages = pages.map(page => {
                page.elements = page.elements.map(element => {
                    if (state.activeElementHashs.includes(element.hash)) {
                        let currentFrame = null;
                        if (objectFrame) {
                            currentFrame = objectFrame;
                        } else {
                            currentFrame = getBoundingBox(element.centerX - element.width / 2, element.centerY - element.height / 2, element.width, element.height, element.rotation)
                        }
                        let dy = 0;
                        let dx = 0;
                        if (alignment === 'left') {
                            dx = referenceFrame.x - currentFrame.x;
                        } else if (alignment === 'center') {
                            dx = referenceFrame.x + referenceFrame.width / 2 - currentFrame.width / 2 - currentFrame.x;
                        } else if (alignment === 'right') {
                            dx = referenceFrame.x + referenceFrame.width - currentFrame.width - currentFrame.x;
                        } else if (alignment === 'top') {
                            dy = referenceFrame.y - currentFrame.y;
                        } else if (alignment === 'middle') {
                            dy = referenceFrame.y + referenceFrame.height / 2 - currentFrame.height / 2 - currentFrame.y;
                        } else if (alignment === 'bottom') {
                            dy = referenceFrame.y + referenceFrame.height - currentFrame.height - currentFrame.y;
                        }
                        element.centerX += dx;
                        element.centerY += dy;
                    }
                    return element;
                });
                return page;
            });


            return {...state, pages: pages, ...produceActiveElements(pages, state.activeElementHashs)};
        case 'SET_ZOOM_SCALE':
            var zoomScale = action.zoomScale;
            return {...state, zoomScale: zoomScale};
        case 'SET_ZOOM_SCALE_BY_CONTAINER_SIZE':
            var currentDesignBackground = null;
            if (state.currentProjectOptions && state.projectOptions && state.projectOptions.images) {
                let imageData = state.projectOptions.images.find(image => {
                    let match = true;
                    for (let option in state.currentProjectOptions) {
                        let variation = state.currentProjectOptions[option];
                        if (image[option] !== variation) {
                            match = false;
                            break;
                        }
                    }
                    return match;
                });
                if (imageData) {
                    currentDesignBackground = imageData;
                }
            }

            var zoomScale = 1;
            if (state.pages.length) {
                var pageWidth = state.pages[0].width + printBleedPadding * 2;
                var pageHeight = state.pages[0].height + printBleedPadding * 2;
                if (currentDesignBackground) {
                    let printingWidth = currentDesignBackground.canvas[0].design_canvas_location.width;
                    let printingHeight = currentDesignBackground.canvas[0].design_canvas_location.height;
                    let printingZoom = 1;
                    if (pageWidth / printingWidth > pageHeight / printingHeight) {
                        printingZoom = pageWidth / printingWidth;
                    } else {
                        printingZoom = pageHeight / printingHeight;
                    }
                    pageWidth = currentDesignBackground.canvas[0].design_background_width * printingZoom;
                    pageHeight = currentDesignBackground.canvas[0].design_background_height * printingZoom;
                }
                if (!action.height) {
                    let designPanelWidth = action.width;
                    zoomScale = designPanelWidth / pageWidth;
                } else {
                    let designPanelWidth = action.width;
                    let designPanelHeight = action.height;
                    let pageWHRatio = pageWidth / pageHeight;
                    let designPanelWHRatio = designPanelWidth / designPanelHeight;
                    if (action.mode === 'fit' ? designPanelWHRatio < pageWHRatio : designPanelWHRatio > pageWHRatio) {
                        zoomScale = designPanelWidth / pageWidth;
                    } else {
                        zoomScale = designPanelHeight / pageHeight;
                    }
                }
            }
            return {...state, zoomScale: zoomScale};
        case 'SHOW_SIDE_BAR':
            return {...state, is_SideBar: true};
        case 'HIDE_SIDE_BAR':
            return {...state, is_SideBar: false};
        case 'SHOW_IMAGE_FILTER':
            var list = state.showImageFilter;
            var name = action.listname;
            var is_display;
            if (action.listname === "sidebar") {
                is_display = false;
            } else if (action.listname === list.listname) {
                if (!list.is_display) {
                    is_display = true;
                } else {
                    is_display = false;
                    name = "";
                }
            } else if (action.listname !== list.listname) {
                is_display = true;
                if (action.listname === "crop") {
                    is_display = false;
                }
            } else {
                if (!list.is_display) {
                    is_display = true;
                } else {
                    is_display = false;
                }
            }

            var show = {
                listname: name,
                is_display: is_display
            };
            return {...state, showImageFilter: show, itemsVisibility: {}, uploadedItemsVisibility: {}};
        case 'MOVE_PAGE_UP':
            var currentPageIndex = getPageIndexByElementHash(state.pages, action.hash);
            if (currentPageIndex > 0) {
                var pages = JSON.parse(JSON.stringify(state.pages));
                var temp = pages[currentPageIndex];
                pages[currentPageIndex] = pages[currentPageIndex - 1];
                pages[currentPageIndex - 1] = temp;
            }
            return {
                ...state,
                pages: pages, ...produceActiveElements(pages, state.activeElementHashs), ...saveHistory(pages)
            };
        case 'MOVE_PAGE_DOWN':
            var currentPageIndex = getPageIndexByElementHash(state.pages, action.hash);
            if (currentPageIndex < state.pages.length - 1) {
                var pages = JSON.parse(JSON.stringify(state.pages));
                var temp = pages[currentPageIndex];
                pages[currentPageIndex] = pages[currentPageIndex + 1];
                pages[currentPageIndex + 1] = temp;
            }
            return {
                ...state,
                pages, ...produceActiveElements(pages, state.activeElementHashs), ...saveHistory(pages)
            };
        case 'DUPLICATE_PAGE':
            let page_data = JSON.parse(JSON.stringify(getObjByHash(action.hash, state.pages)));  // new identical clone
            if (page_data) {
                var currentPageIndex = getPageIndexByElementHash(state.pages, action.hash); // origin page index
                var pages = JSON.parse(JSON.stringify(state.pages));
                let reassignMap = {}; // pepare map
                assignHashToObj(page_data, true, reassignMap); //
                page_data.elements = page_data.elements.map(element => { // reassign Hash to new identical clone
                    if (element.type === 'group') {
                        element.elements = element.elements.map(elementHash => {
                            if (reassignMap[elementHash]) {
                                return reassignMap[elementHash];
                            } else {
                                return elementHash;
                            }
                        })
                    }
                    return element;
                });
                pages.splice(currentPageIndex, 0, page_data);
                return {
                    ...state,
                    pages, activeElementHashs: [], activeElements:[], ...saveHistory(pages)
                };
            }
            return {
                ...state
            };
        case 'SAVE_HISTORY':
            return {...state, ...saveHistory(state.pages)};
        case 'UNDO_HISTORY':
            if (state.currentHistory > 0) {
                var lastPages = JSON.stringify(state.histories[state.currentHistory].pages);
                var lastOptions = JSON.stringify(state.histories[state.currentHistory].currentProjectOptions);
                var currentPages = JSON.stringify(state.pages);
                if (currentPages === lastPages) {
                    let data = JSON.parse(JSON.stringify(state.histories[state.currentHistory - 1]));
                    let pages = data.pages;
                    let currentProjectOptions = data.currentProjectOptions;
                    return {
                        ...state,
                        currentHistory: state.currentHistory - 1,
                        pages,
                        currentProjectOptions,
                        currentSaveId: state.currentSaveId + 1, ...produceActiveElements(pages, state.activeElementHashs.filter(hash => getObjByHash(hash, state.histories[state.currentHistory - 1])))
                    };
                } else {
                    let pages = JSON.parse(lastPages);
                    let currentProjectOptions = JSON.parse(lastOptions);
                    return {
                        ...state,
                        pages: pages,
                        currentProjectOptions, ...produceActiveElements(pages, state.activeElementHashs.filter(hash => getObjByHash(hash, lastPages)))
                    };
                }
            } else {
                return state;
            }
        case 'REDO_HISTORY':
            if (state.currentHistory < state.histories.length - 1) {
                let pages = JSON.parse(JSON.stringify(state.histories[state.currentHistory + 1].pages));
                let currentProjectOptions = JSON.parse(JSON.stringify(state.histories[state.currentHistory + 1].currentProjectOptions));
                return {
                    ...state,
                    currentHistory: state.currentHistory + 1,
                    pages: pages,
                    currentProjectOptions,
                    currentSaveId: state.currentSaveId + 1, ...produceActiveElements(pages, state.activeElementHashs.filter(hash => getObjByHash(hash, state.histories[state.currentHistory + 1])))
                };
            } else {
                return state;
            }
        case 'SET_SAVE_ID':
            var saveId = action.saveId;
            return {...state, lastSaveId: saveId};
        case 'SET_SAVE_ERROR':
            return {...state, currentSaveId: action.saveError};
        case 'ALIGN_SAVE_ID':
            return {...state, lastSaveId: state.currentSaveId};
        case 'SET_PRINT_ONLY_LOADED':
            return {...state, print_only_loaded: true};
        case 'SET_FOCUS_PAGE':
            return {...state, focusPageId: action.pageId};
        case 'SET_PROJECT_CODE':
            return {...state, projectCode: action.projectCode};
        case 'SET_PROJECT_PRODUCT_ID':
            return {...state, productId: action.productId};
        case 'SET_CUSTOMISE_PREVIEW':
            return {...state, is_customise_further: action.is_customise_further};
        case 'CLEAR_ALL_POPUP':
            return {...state, itemsVisibility: {}, uploadedItemsVisibility: {}};
        case 'SET_IS_DOWNLOADING_OUTFILE':
            var isDownloadingOutfile = action.isDownloadingOutfile;
            return {...state, isDownloadingOutfile: isDownloadingOutfile};
        case 'TOGGLE_ITEM_VISIBILITY':
            var itemCode = action.itemCode;
            var itemsVisibility = JSON.parse(JSON.stringify(state.itemsVisibility));
            itemsVisibility[itemCode] = !state.itemsVisibility[itemCode];
            Object.keys(itemsVisibility).map(item => {
                if (item !== itemCode) {
                    itemsVisibility[item] = false;
                }
            });
            // debugger;
            return {...state, itemsVisibility, showlist: {}};
        case 'SET_PROJECT_TARGET':
            return {...state, project_target: action.project_target};
        case 'SET_ACCESS_LEVEL':
            return {...state, access_level: action.access_level};
        case 'SET_PERMISSION':
            if (action.permission=='read')
            {
                console.log({permission: action.permission, 'code': state.projectCode})
            }
            return {...state, permission: action.permission};
        case 'SET_ENGINE_READY':
            return {...state, engineReady: action.engineReady};
        case 'SET_PRODUCT_NAME':
            return {...state, productName: action.productName};
        case 'SET_USERNAME':
            var hash = '126';
            if (action.username) {
                let today = new Date();
                hash = (today.getMonth() + 1) + 'M' + today.getDate() + 'D';
            }
            return {...state, username: action.username, hash};
        case 'SET_MY_FAVOURITES':
            var hash = state.hash ? state.hash : '126';
            if (action.element_id) {
                hash += action.element_id

                window.caches.open('elements').then(cache => cache.matchAll('https://studio.artfia.com/ajax/design/getDesignElement', {ignoreSearch: true}).then(function(response) {
                    // console.log(response);
                    response.forEach(function(element) {
                        // console.log(element, index, array);
                        cache.delete(element);
                    });
                }));
            }
            return {...state, username: action.username, hash};
        case 'SET_PAYMENT_TERM':
            return {...state, payment_term: action.payment_term};
        case 'SET_SELECT_FROM_TEAM_ID':
            return {...state, select_from_team_id: action.select_from_team_id, team: action.team};
        case 'SET_PROJECT_NAME':
            return {
                ...state,
                project_name: action.project_name,
                currentSaveId: action.project_name !== state.project_name ? (state.currentSaveId + 1) : state.currentSaveId
            };
        case 'SET_CALLBACK_URL':
            return {...state, callback_url: action.callback_url};
        case 'SET_MAIN_URL':
            return {...state, main_url: action.main_url};
        case 'SET_SAVE_URL':
            return {...state, save_url: action.save_url};
        case 'SET_PARTNERSHIP_LINK':
            return {...state, partnership_link: action.partnership_link};
        case 'SET_PARTNERSHIP_PARAM':
            return {...state, partnership_param: action.partnership_param};
        case 'SET_PROJECT_WATERMARK':
            return {...state, has_watermark: action.has_watermark};
        case 'SET_IS_PRINTABLE':
            return {...state, is_printable: action.is_printable};
        case 'RESIZE_PAGE' :
            var pages = JSON.parse(JSON.stringify(state.pages));
            pages = pages.map(page => resizePage(page, parseFloat(action.width), parseFloat(action.height)));
            return {...state, pages, ...produceActiveElements(pages, state.activeElementHashs)};
        case 'RESET_CANVAS' :
            var pages = [{
                width: state.pages[0].width,
                height: state.pages[0].height,
                elements: []
            }];
            var background = {
                type: 'background',
                width: pages[0].width,
                height: pages[0].height,
                centerX: pages[0].width / 2,
                centerY: pages[0].height / 2,
                imageWidth: pages[0].width + printBleedPadding * 2,
                imageHeight: pages[0].height + printBleedPadding * 2,
                imageOriginLeft: -printBleedPadding,
                imageOriginTop: -printBleedPadding,
                rotation: 0,
            };


            pages[0].elements.unshift(background);
            pages = assignHashToObj(pages);
            return {...state, pages, ...produceActiveElements(pages, [])};
        case 'RESET_FOCUS_PAGE':
            var pages = JSON.parse(JSON.stringify(state.pages));
            if (pages[state.focusPageId]) {
                pages[state.focusPageId].elements = [];
            }
            return {...state, pages, ...produceActiveElements(pages, [])};
        case 'TOGGLE_SHOW_MARGIN' :
            return {...state, isShowingMargin: !state.isShowingMargin};
        case 'TOGGLE_SHOW_PRINT_BLEED' :
            return {...state, isShowingPrintBleed: !state.isShowingPrintBleed};
        case 'SHOW_PRINT_BLEED' :
            return {...state, isShowingPrintBleed: true};
        case 'HIDE_PRINT_BLEED' :
            return {...state, isShowingPrintBleed: false};
        case 'SET_FOCUS_EDIT_TEXT':
            return {...state, focusEditText: action.hash};
        case 'SET_SHOW_DROPZONE':
            return {...state, showDropzone: action.visibility};
        case 'SET_DRAG_IMAGE_DATA':
            return {...state, dragImageData: action.imageData};
        case 'CREATE_BG_APPLY_DRAG_IMAGE_PREVIEW':
            var pages = JSON.parse(JSON.stringify(state.pages));
            var dpage = pages[0];
            var background = dpage.elements.find(element => element.type === 'background');
            if (!background) {
                background = {
                    type: 'background',
                    width: dpage.width,
                    height: dpage.height,
                    centerX: dpage.width / 2,
                    centerY: dpage.height / 2,
                    imageWidth: action.width + printBleedPadding * 2,
                    imageHeight: action.height + printBleedPadding * 2,
                    imageOriginLeft: -printBleedPadding,
                    imageOriginTop: -printBleedPadding,
                    rotation: 0,
                };

                background = assignHashToObj(background);
                dpage.elements.unshift(background);
                return {...state, pages, ...produceActiveElements(pages, state.activeElementHashs)};
            } else {
                return {...state};
            }
        case 'APPLY_DRAG_IMAGE_PREVIEW':
            var pages = state.pages;
            var changed = false;
            pages.forEach(page => {
                page.elements.forEach(element => {
                    if (element.hash === action.hash) {
                        if (element.preview !== action.imageData) {
                            changed = true;
                        }
                    } else {
                        if (element.preview !== null) {
                            changed = true;
                        }
                    }
                })
            });
            if (changed) {
                pages = JSON.parse(JSON.stringify(state.pages));
                pages.forEach(page => {
                    page.elements.forEach(element => {
                        if (typeof element === 'string') return;
                        if (element.hash === action.hash) {
                            element.preview = action.imageData;
                        } else {
                            element.preview = null;
                        }
                    })
                })
            }
            return {...state, pages, ...produceActiveElements(pages, state.activeElementHashs)};
        case 'SET_AUTH_STATUS':
            return {...state, authStatus: action.authStatus};
        case 'SET_VERFIFY_STATUS':
            /**
             * @FIXME replace photoUrl Storage::disk('s3')->move('uploads/'.$api_token, 'uploads/'.session('username'));
             * @FIXME replace mine upload Storage::disk('s3')->move('uploads/'.$api_token, 'uploads/'.session('username'));
             * action.guest_token
             * action.username
             * @TODO if server not move it, then just cleanup MineUpload
             */
            return {
                ...state,
                verifyStatus: action.verifyStatus,
                verifyNickname: action.verifyNickname,
                verifyEmail: action.verifyEmail
            };
        case 'SET_ALERT_BOX':
            return {...state, alertBoxContent: action.alertBoxContent};

        case 'SHOW_UPLOAD_ITEM_ACTION_BOX':
            var list = state.uploadedItemsVisibility;
            var name = action.actionBoxId;
            var is_display;
            if (action.actionBoxId === list.actionBoxId) {
                if (!list.is_display) {
                    is_display = true;
                } else {
                    is_display = false;
                    name = "";
                }
            } else {
                is_display = true;
            }

            var show = {
                actionBoxId: name,
                is_display: is_display
            };
            return {...state, uploadedItemsVisibility: show, itemsVisibility: {}};
        case 'TEXT_USED_COLOR_LIST':
            var pages = JSON.parse(JSON.stringify(state.pages));
            var textColorList = [];
            pages.forEach(page => {
                page.elements.forEach(element => {
                    if (element.textColor || element.backgroundColor || element.svgColors && element.svgColors.length >= 1) {
                        var color;
                        if (element.textColor) {
                            color = element.textColor;
                        } else if (element.backgroundColor) {
                            color = element.backgroundColor;
                        } else {
                            element.svgColors.forEach(svgColor => {
                                let targetColor = svgColor;
                                if (element.colorMap && element.colorMap[svgColor]) {
                                    targetColor = element.colorMap[svgColor];
                                }
                                if (!targetColor || targetColor === '') {
                                    targetColor = '#000000';
                                }
                                color = targetColor;
                            })
                        }
                        var indexOfStevie = textColorList.findIndex(i => i === color);
                        if (indexOfStevie === -1) {
                            textColorList.push(color);
                        }
                    }
                })
            });
            return {...state, textColorList: textColorList};
        case 'ADD_NEW_TEXT':
            var textData = null;
            if (state.lastFocusTextHash) {
                var lastFocusText = getObjByHash(state.lastFocusTextHash, state.pages);
                if (lastFocusText) {
                    textData = {
                        "type": "text",
                        "pages": [{
                            "width": state.pages[0].width,
                            "height": state.pages[0].height,
                            "elements": [JSON.parse(JSON.stringify(lastFocusText))]
                        }]
                    };
                    textData.pages[0].elements[0].textContent = 'Add Text';
                    textData.pages[0].elements[0].hash = null;
                }

            }
            if (!textData && state.pages[state.focusPageId]) {
                state.pages[state.focusPageId].elements.forEach(element => {
                    if (element.type === 'text') {
                        textData = {
                            "type": "text",
                            "pages": [{
                                "width": state.pages[0].width,
                                "height": state.pages[0].height,
                                "elements": [JSON.parse(JSON.stringify(element))]
                            }]
                        };
                        textData.pages[0].elements[0].textContent = 'Add Text';
                        textData.pages[0].elements[0].hash = null;
                    }
                })
            }
            if (!textData) {
                textData = {
                    "element": {
                        "centerX": 100,
                        "centerY": 100,
                        "width": 150,
                        "height": 20,
                        "rotation": 0,
                        "scaleX": 1,
                        "scaleY": 1,
                        "opacity": 1,
                        "type": "text",
                        "textContent": "Add text",
                        "textFontFamily": "Arial",
                        "textFontSize": 18,
                        "textColor": "#333",
                        "textBold": false,
                        "textItalic": false,
                        "textAlignment": "left",
                        "textUpper": false,
                        "textList": false,
                        "textLetterSpacing": 0,
                        "textLineHeight": 1.4,
                        "textAnchorTextBox": "middle"
                    }
                };
            }
            var newData = addElementByData(textData, JSON.parse(JSON.stringify(state.pages)));
            return {
                ...state,
                pages: newData.pages, ...produceActiveElements(newData.pages, newData.activeElementHashs), ...saveHistory(newData.pages)
            };
        case 'SET_COPY_DATA':
            var copyData = {
                "pages": [{
                    "width": state.pages[0].width,
                    "height": state.pages[0].height,
                    "elements": JSON.parse(JSON.stringify(state.activeElements))
                }]
            };
            return {...state, copyData};
        case 'PASTE_COPY_DATA':
            if (state.copyData) {
                var copyData = JSON.parse(JSON.stringify(state.copyData));
                var reassignMap = {};
                assignHashToObj(copyData.pages, true, reassignMap);
                copyData.pages[0].elements = copyData.pages[0].elements.map(element => {
                    if (element.type === 'group') {
                        element.elements = element.elements.map(elementHash => {
                            if (reassignMap[elementHash]) {
                                return reassignMap[elementHash];
                            } else {
                                return elementHash;
                            }
                        })
                    }
                    return element;
                });
                var newData = addElementByData(copyData, JSON.parse(JSON.stringify(state.pages)));
                return {
                    ...state,
                    copyData,
                    pages: newData.pages, ...produceActiveElements(newData.pages, newData.activeElementHashs), ...saveHistory(newData.pages)
                };
            } else {
                return {...state};
            }
        case 'SET_COLOR_PROPERTY':
            var property = action.property;
            var color = action.color;
            var svgColor = action.svgOriginalcolor;
            return {...state, setProperty: property, currentColor: color, originalSVGColor: svgColor};
        case 'SET_CUSTOM_FONTS':
            var fonts = action.data;
            var customFontList = [];
            for (var i = 0; i < fonts.length; i++) {
                let custom_font = {
                    value: fonts[i].value,
                    label: fonts[i].label,
                    img: fonts[i].img
                };
                if (customFontList.length >= 1) {
                    var index = customFontList.findIndex(x => x.label === fonts[i].label);
                    if (index === -1) {
                        customFontList.push(custom_font);
                    }
                } else {
                    customFontList.push(custom_font);
                }
            }
            return {...state, custom_fonts: customFontList};
        case 'SET_CUSTOM_COLORS':
            var color_list = action.data;
            var customColorList = [];
            for (var i = 0; i < color_list.length; i++) {
                if (customColorList.length >= 1) {
                    var index = customColorList.findIndex(x => x.label === color_list[i]);
                    if (index === -1) {
                        customColorList.push(color_list[i]);
                    }
                } else {
                    customColorList.push(color_list[i]);
                }
            }
            return {...state, custom_colors: customColorList};
        case 'LOAD_BRAND_KIT':
            var brand_data = action.data;
            var fonts = [], colors = [], brand_token_list = [], brand_images = [],// original_fonts_name = [],
                custom_font_brand_token = [];
            for (var i = 0; i < brand_data.length; i++) {
                var fonts_list = brand_data[i].fonts_original_name && brand_data[i].fonts_original_name !== "[]" ? JSON.parse(brand_data[i].brand_fonts) : [];
                var f_list = [];
                if (fonts_list && fonts_list.length > 0) {
                    for (var j = 0; j < fonts_list.length; j++) {
                        var font_label = fonts_list[j].indexOf(".") > 0 ? fonts_list[j].split(".")[0] : fonts_list[j];
                        if (brand_data[i].fonts_original_name && fonts_list.length === JSON.parse(brand_data[i].fonts_original_name).length) {
                            var fonts_name_list = brand_data[i].fonts_original_name ? JSON.parse(brand_data[i].fonts_original_name) : [];
                            font_label = fonts_name_list[j].indexOf(".") > 0 ? fonts_name_list[j].split(".")[0] : fonts_name_list[j];
                        }
                        var font_value = fonts_list[j].indexOf(".") > 0 ? fonts_list[j].split(".")[0] : fonts_list[j];
                        let custom_font = {
                            value: font_value,
                            label: font_label
                        };
                        f_list.push(custom_font);
                    }
                    var brandfont = {
                        brand_name: brand_data[i].brand_name,
                        brand_font_list: f_list
                    };
                    fonts.push(brandfont);
                    custom_font_brand_token.push(brand_data[i].token);
                }


                var color_list = brand_data[i].brand_colors && brand_data[i].brand_colors !== "[]" ? JSON.parse(brand_data[i].brand_colors) : [];
                if (color_list && color_list.length > 0) {
                    var brand_color = {
                        brand_name: brand_data[i].brand_name,
                        brand_color_list: color_list
                    };
                    colors.push(brand_color);
                }


                var images_list = brand_data[i].brand_images && brand_data[i].brand_images !== "[]" ? JSON.parse(brand_data[i].brand_images) : [];
                if (images_list && images_list.length > 0) {
                    var brand_img = {
                        brand_name: brand_data[i].brand_name,
                        brand_image_list: images_list,
                        brand_token: brand_data[i].token
                    };
                    brand_images.push(brand_img);
                }

                brand_token_list.push(brand_data[i].token);
            }
            return {
                ...state,
                custom_colors: colors,
                custom_fonts: fonts,
                brand_token: brand_token_list,
                custom_images: brand_images,
                custom_font_brand_token: custom_font_brand_token
            };
        case 'LOAD_PROJECT_FONTS' :
            var project_fonts = action.data;
            return {...state, load_project_fonts: project_fonts};
        case 'SET_PRODUCT_LIST':
            return {...state, productList: action.productList};
        case 'CLOSE_ELEMENT_MENU':
            return {...state, closeElementMenuClass: true, is_SideBar: false};
        case 'CHANGE_ELEMENT_MENU':
            return {...state, closeElementMenuClass: false, is_SideBar: true};
        case 'SET_ELEMENT_AS_BACKGROUND':
            var pages = JSON.parse(JSON.stringify(state.pages));
            var pageIndex = getPageIndexByElementHash(pages, action.hash);
            var currentPage = pages[pageIndex];
            var element = getObjByHash(action.hash, currentPage);
            var currentPageNewElements = [];
            currentPage.elements.forEach(element => {
                if (element.type !== 'background') {
                    currentPageNewElements.push(element);
                }
            });
            currentPage.elements = currentPageNewElements;
            if (element)
            {
                element.type = 'background';
            }
            var data = {
                pages: [{
                    width: element.width,
                    height: element.height,
                    elements: [element]
                }]
            };

            var newData = addElementByData(data, pages);

            return {
                ...state,
                pages: newData.pages, ...produceActiveElements(newData.pages, newData.activeElementHashs)
            };
        case 'CLEAN_CHILD_GROUP':
            var pages = JSON.parse(JSON.stringify(state.pages));
            var activeElementHashs = JSON.parse(JSON.stringify(state.activeElementHashs));
            var groupElement = getObjByHash(action.groupElement.hash, pages);
            var cleanElements = [];
            groupElement.elements.forEach(childElementHash => {
                let childElement = getObjByHash(childElementHash, pages);
                if (childElement.type === 'group') {
                    cleanElements.push(childElement);
                }
            });
            cleanElements.forEach(childGroupElement => {
                let pageIndex = getPageIndexByElementHash(pages, childGroupElement.hash);
                let page = pages[pageIndex];
                page.elements = page.elements.filter(element => childGroupElement.hash !== element.hash);
                activeElementHashs = activeElementHashs.filter(activeElementHash => activeElementHash !== childGroupElement.hash)
            });
            groupElement.elements = groupElement.elements.filter(groupElementHash => !cleanElements.find(cleanElement => cleanElement.hash === groupElementHash));

            return {...state, pages, ...produceActiveElements(pages, activeElementHashs)};
        case 'SET_PROJECT_IS_LOADED':
            var is_loaded = action.is_loaded;
            return {...state, is_project_loaded: is_loaded};
        case 'SET_LANG':
            var lang = action.lang;
            return {...state, current_lang: lang};
        case 'SET_MODAL_RANDOM':
            var random = action.random;
            return {...state, random: random};
        case 'SET_RECENT_USED_IMAGE_LIST':
            return {
                ...state,
                recentlyUsedImages: action.recentlyUsedImages,
                recentlyUsedPhotos: action.recentlyUsedPhotos
            };
        case 'UPDATE_RECENT_USED_IMAGES':
            var imgtype = action.imageType;
            var imageid = action.recentSelectId;

            var recently_used_img_list = state.recentlyUsedImages ? state.recentlyUsedImages : [];
            var recently_used_photo_list = state.recentlyUsedPhotos ? state.recentlyUsedPhotos : [];
            if (imgtype === "photo") {
                if (recently_used_photo_list.indexOf(imageid) < 0) {
                    recently_used_photo_list.push(imageid);
                } else {
                    for (var i = recently_used_photo_list.length; i--;) {
                        if (recently_used_photo_list[i] === imageid) {
                            recently_used_photo_list.splice(i, 1);
                        }
                    }
                    recently_used_photo_list.push(imageid);
                }
            } else {
                if (recently_used_img_list.indexOf(imageid) < 0) {
                    recently_used_img_list.push(imageid);
                } else {
                    for (var i = recently_used_img_list.length; i--;) {
                        if (recently_used_img_list[i] === imageid) {
                            recently_used_img_list.splice(i, 1);
                        }
                    }
                    recently_used_img_list.push(imageid);
                }
            }
            return {...state, recentlyUsedImages: recently_used_img_list, recentlyUsedPhotos: recently_used_photo_list};
        case 'LOCK_ACTIVE_ELEMENTS':
            var shouldLock = action.shouldLock;
            var pages = produce(state.pages, pages => {
                var activeElementHashs = state.activeElementHashs;
                if (activeElementHashs) {
                    var groupElement = resolveGroupElement(state.activeElementHashs);
                    if (groupElement) {
                        applyChangeToElement(groupElement.hash, pages, {locked: shouldLock});
                    }
                    activeElementHashs.forEach(activeElementHash => {
                        applyChangeToElement(activeElementHash, pages, {locked: shouldLock});
                    })
                }
            });
            return {...state, pages, ...produceActiveElements(pages, state.activeElementHashs)};
        case 'SET_PROJECT_OPTIONS':
            return {...state, projectOptions: action.projectOptions};
        case 'SET_PRESET_PRINT_DESIGN_OPTION':
            if (state.max_page === 2 && state.min_page === 1) {
                //default print design
                if (!state.currentProjectOptions["print_design"]) {
                    var print_design = "single";
                    if (state.pages.length > 1) {
                        print_design = "double"
                    }
                    state.currentProjectOptions["print_design"] = print_design
                }
                if (state.projectOptions && state.projectOptions.images) {
                    state.projectOptions.images.forEach(image => {
                        image["print_design"] = state.currentProjectOptions["print_design"]
                    })
                }
                //Add print design to projectOptions option in marketplace
                if (state.projectOptions && state.projectOptions.options && !state.engineReady) {
                    var printDesignOptions = {
                        id: 1099,
                        name: 'print_design',
                        displayName: 'Print Design',
                        is_show_engine: 0,
                        variations: [{
                            'id': 1,
                            'name': 'single',
                            'displayName': 'Single',
                        }, {
                            'id': 2,
                            'name': 'double',
                            'displayName': 'Double',
                        }],
                    };
                    state.projectOptions.options.push(printDesignOptions);
                }
            }
            return {...state, ...saveHistory(state.pages)};
        case 'SET_PRINT_DESIGN_OPTION':
            let newOptions = JSON.parse(JSON.stringify(state.currentProjectOptions));
            var design_show_Front_text = "";
            var design_show_Back_text = "";
            var backdesign_page = 0;
            newOptions[action.option] = action.variation;
            if (newOptions && state.projectOptions && state.projectOptions.images) {
                if (action.option === "print_design" && action.variation === "double") {
                    design_show_Front_text = "Front";
                    design_show_Back_text = "Back";
                    backdesign_page = state.pages.length - 1
                }
                state.projectOptions.images.forEach(image => {
                    image[action.option] = action.variation;
                    if (action.variation === "double" && image.previews.length === 1) {
                        let newPreview = JSON.parse(JSON.stringify(image.previews[image.previews.length - 1]));
                        image.previews.push(newPreview);
                    } else if (action.variation === "single" && image.previews.length > 1) {
                        image.previews.splice(1, 1)
                    }
                    image.previews.forEach((preview, index) => {
                        if (index === image.previews.length - 1) {
                            preview["design_page"] = backdesign_page;
                            preview["design_show_text"] = design_show_Back_text
                        } else {
                            preview["design_show_text"] = design_show_Front_text
                        }
                    })
                });

                var pages = JSON.parse(JSON.stringify(state.pages));
                /*
                pages.forEach(page => {
                  if (state.pages.length>0 && action.option==="print_design" && action.variation==="double"){
                    page.commonBack = false;
                  }else{
                    page.commonBack = true;
                  }
                })
                */
            }
            return {...state, currentProjectOptions: newOptions, pages, ...saveHistory(state.pages)};
        case 'SET_CURRENT_PROJECT_OPTION':
            if (state.projectOptions && state.projectOptions.options && state.projectOptions.options.find(option => option.name === action.option) && state.currentProjectOptions[action.option] !== action.variation) {
                let newOptions = JSON.parse(JSON.stringify(state.currentProjectOptions));
                newOptions[action.option] = action.variation;
                //let currentDesignBackground = null;
                if (newOptions && state.projectOptions && state.projectOptions.images) {
                    let imageData = state.projectOptions.images.find(image => {
                        let match = true;
                        for (let option in newOptions) {
                            let variation = newOptions[option];
                            if (image[option] !== variation) {
                                match = false;
                                break;
                            }
                        }
                        return match;
                    });
                    if (imageData) {
                        currentDesignBackground = imageData;
                    }
                }
                return {...state, currentProjectOptions: newOptions, ...saveHistory(state.pages)}
            } else {
                return {...state}
            }
        case 'SET_HIDE_PAGE_OPTION':
            return {
                ...state,
                hidePageOption: action.hidePageOption,
                max_page: action.max_page,
                min_page: action.min_page
            };
        case 'GO_PREVIEW_PAGE':
            return {...state, previewPage: action.page};
        case 'GO_PREVIEW_PAGE_BY_TAG':
            var currentDesignBackground = null;
            if (state.currentProjectOptions && state.projectOptions && state.projectOptions.images) {
                let imageData = state.projectOptions.images.find(image => {
                    let match = true;
                    for (let option in state.currentProjectOptions) {
                        let variation = state.currentProjectOptions[option];
                        if (image[option] !== variation) {
                            match = false;
                            break;
                        }
                    }
                    return match;
                });
                if (imageData) {
                    currentDesignBackground = imageData;
                }
            }
            if (currentDesignBackground) {
                var index = currentDesignBackground.previews.findIndex(preview => preview.tag === action.tag);
                if (index !== -1) {
                    return {...state, previewPage: index}
                }
            }
            return {...state};
        case 'TOGGLE_PREVIEW_MIN':
            return {...state, previewPanelMin: !state.previewPanelMin};
        case 'APPLY_TEXT_FOR_ACTIVE_ELEMENTS':
            var pages = produce(state.pages, pages => {
                state.activeElements.filter(element => element).forEach(activeElement => {
                    if (activeElement.type === 'text' && typeof activeElement.editText !== 'undefined' && activeElement.editText !== null) {
                        applyChangeToElement(activeElement.hash, pages, {
                            textContent: activeElement.editText,
                            editText: null
                        });
                    }
                })
            });
            return {...state, pages: pages, ...produceActiveElements(pages, state.activeElementHashs)};
        case 'SET_PROXY_EVENT':
            return {...state, proxyEvent: action.proxyEvent};
        case 'SET_EDIT_TEXT_ELEMENT':
            return {...state, editTextElement: action.element};
        case 'SET_PROXY_HOVER_ELEMENT_HASH':
            return {...state, proxyHoverElementHash: action.hash};
        case 'SET_WORD_WRAP_HINT':
            var wordwrapHints = state.wordwrapHints ? JSON.parse(JSON.stringify(state.wordwrapHints)) : {};
            wordwrapHints[action.hash] = action.hint;
            return {...state, wordwrapHints: wordwrapHints};
        case 'APPLY_WORD_WRAP_HINT':
            var pages = produce(state.pages, pages => {
                pages.forEach(page => {
                    page.elements.forEach(element => {
                        if (state.wordwrapHints) {
                            for (var hash in state.wordwrapHints) {
                                if (element.hash === hash) {
                                    element.wordwrapHint = state.wordwrapHints[hash];
                                }
                            }
                        }
                    })
                })
            });
            return {...state, pages, wordwrapHints: null};
        case 'SET_CUSTOM_TEXT':
            /**
             * @deprecated
             * @type {{}}
             */
            var customTexts = {};
            if (state.customTexts) {
                customTexts = JSON.parse(JSON.stringify(state.customTexts));
            }
            if (action.customText) {
                obj = getObjByHash(action.hash, state.pages);
                if (obj['maxChar']) {
                    action.customText = action.customText.substr(0, obj['maxChar']);
                }
            }

            var changes = {textContent: action.customText};
            var elementHash = action.hash;

            var pages = produce(state.pages, pages => {
                applyChangeToElement(elementHash, pages, changes);
            });

            customTexts[action.hash] = {...customTexts[action.hash], textContent: action.customText};

            return {...state, pages: pages, customTexts};
        case "GET_IMAGES":
            return {...state};
        case 'SET_CUSTOM_TEXT_PROPS':
            //console.log('SET_CUSTOM_TEXT_PROPS')
            var customTexts = {};
            if (state.customTexts) {
                customTexts = JSON.parse(JSON.stringify(state.customTexts));
            }
            if (action.props.customText) {
                obj = getObjByHash(action.hash, state.pages);
                if (obj['maxChar']) {
                    action.props.customText = action.props.customText.substr(0, obj['maxChar']);
                }
            }

            var changes = action.props;
            var elementHash = action.hash;

            var pages = produce(state.pages, pages => {
                applyChangeToElement(elementHash, pages, changes);
            });

            customTexts[action.hash] = {...customTexts[action.hash], ...action.props};

            return {...state, pages: pages, customTexts};
        case 'CHANGE_PHOTO_URL':
            var customTexts = {};
            if (state.customTexts) {
                customTexts = JSON.parse(JSON.stringify(state.customTexts));
            }
            //var changes = {photoUrl: action.photoUrl};
            var elementHash = action.hash;

            var pages = produce(state.pages, pages => {
                applyChangeToElement(elementHash, pages, action.changes);
            });

            customTexts[action.hash] = {...customTexts[action.hash], ...action.changes};

            return {...state, pages: pages, customTexts};
        case 'SET_CUSTOM_FONT_SIZE':
            var customTexts = {};
            if (state.customTexts) {
                customTexts = JSON.parse(JSON.stringify(state.customTexts));
            }
            customTexts[action.hash] = {
                ...customTexts[action.hash],
                textFontSize: action.fontSize,
                height: action.fontSize
            };
            return {...state, customTexts};
        case 'SHOW_POPUP_UPLOAD':
            let imageUploading = {...action};
            if (!action.hash) {
                imageUploading = null;
            }
            return {...state, imageUploading};
        case 'TOGGLE_COMMON_BACK':
            var pages = JSON.parse(JSON.stringify(state.pages));
            pages.forEach(page => {
                if (page.commonBack) {
                    page.commonBack = false;
                }
            });
            if (action.hash) {
                page = getObjByHash(action.hash, pages);
                if (page) {
                    page.commonBack = true;
                }
            }
            return {...state, pages, ...saveHistory(pages)};
        case 'SET_CUSTOM_ARTWORK':
            // @TODO get number of pages
            let artworks = state.artworks;
            if (!artworks) artworks = Array(state.pages.length).fill(undefined);

            artworks[action.page] = action.artwork;

            return {...state, artworks};
        default:
            return state
    }

};

export default app
