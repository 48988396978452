export default 
    [  
      {  
         "width":336,
         "height":336,
          "backgroundColor": '#ededed',
         "elements":[
            {
              "centerX":168,
              "centerY":168,
              "width":336,
              "height":336,
              "rotation":0,
              "type":"background",
              "imageWidth":504,
              "imageHeight":336,
              "imageOriginLeft":-75,
              "imageOriginTop":2,
              "photoUrl":"http://testing.joinprint.com.hk/images/screen.jpg",
              "hash":"yTDJAM0h32js2zVHoTv6WsZ8Zwr6ULfE"
            },
            {  
               "rotation":45,
               "centerX":85.85786437626905,
               "centerY":112.42104986499055,
               "width":200,
               "height":57,
               "type":"text",
               "textContent":"Burger",
               "textFontFamily":"AlternateGotNo2D",
               "textFontSize":"62",
               "textBold":true,
               "textColor":"#000",
               "hash":"LSjLPpFFGQeugi6T9c1sfLltpeu6VR0R",
               "textAlignment":"center"
            },
            {  
               "centerX":200,
               "centerY":208,
               "width":100,
               "height":66,
               "type":"text",
               "textContent":"Bar",
               "textFontFamily":"AlternateGotNo2D",
               "textFontSize":"70",
               "textColor":"#000",
               "hash":"UvR9GJRe6SrmkKbZOnLgeL542xfBjODV",
               "rotation":0,
               "textAlignment":"center"
            },
            {  
               "centerX":246.0480947161671,
               "centerY":71,
               "width":100,
               "height":150,
               "rotation":0,
               "type":"photo",
               "imageWidth":200,
               "imageHeight":200,
               "imageOriginLeft":-75,
               "imageOriginTop":-25,
               "photoUrl":"https://www.tasteofhome.com/wp-content/uploads/2017/10/exps28800_UG143377D12_18_1b_RMS-696x696.jpg",
               "hash":"yTDJAM0h32js2zVHoTv6WsZ8Zwr6ULfW"
            }
         ],
         "hash":"pKqTH0nrixqEEHgCuAOCzETj2uCy8qO2"
      },
      {  
         "width":336,
         "height":336,
         "elements":[  

         ],
         "hash":"I522I2tdgzENR88AnuC6hQteL5OgWkPd"
      }
   ]
