import React from 'react';
import {connect} from 'react-redux';
// import Cookies from 'universal-cookie';
import fonts from '../../data/fonts';
//css
import './elementControlPanel.css';
import adjust_icon from './adjust.png';
import lock_icon from './lock.png';
import copy_icon from '../Page/copy-page.svg';
import unlock_icon from './unlock.png';
//view
//import TextStyleControl from './TextStyleControl';
import TextAlignmentPicker from './TextAlignmentPicker';
import TextColorPicker from './TextColorPicker';
import DeleteComponent from './DeleteElement';
import PositionComponent from './PositionPicker';
import Transparency from './Transparency';
import Popup from './Popup';
//import ButtonTextComponent from "./List";
//import ButtonComponent from "./Button";
//import IconButton from "../IconButton";
import SliderPicker from "./SliderPicker"
import TextInputControl from "./TextInputControl"
import FlipControl from "./FlipControl"
import ConstraintPanel from "./ConstraintPanel"

import {
    alignElements,
    applyTextForActiveElements,
    cleanChildGroup,
    clearActiveElements,
    copyElement,
    // getCustomFonts,
    saveHistory,
    setActiveElementProperty,
    // setActivePageProperty,
    setAdjustMode,
    setColorPickerMode,
    setColorProperty,
    setCropMode,
    setDefaultMode,
    setEditTextElement,
    setElementAsBackground,
    setElementProperty,
    setFilterMode,
    setLockActiveElements,
    setTransformMode,
    showFontList,
    showImageFilter,
    showSideBar,
    toggleActualGroup,
    ungroupVirtualGroup
} from '../../actions';

import {resolveGroupElement} from "../../utilities/canvasData";
import Button from "./Button/Button";
import './FontPicker/fonts.scss';

import './SliderPicker/letter_spacing.svg';
import './SliderPicker/line_height.svg';


class ElementControlPanel extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = ({
            ishandleFilter: false,
            pageIdx: props.pageIdx,
            elementIdx: props.elementIdx,
            activefilter: '',
            selectedFonts: [],
            current_username: props.username
        });
        this.font_family_options_arr_eng = fonts.engFonts;

        this.font_family_options_arr_other = fonts.otherFonts;

        this.font_family_options_arr_uploaded = [];

        this.font_size_options_arr = [];

        this.handleFontList = this.handleFontList.bind(this);
        this.setActiveTextProperty = this.setActiveTextProperty.bind(this);
        this.handleColorPicker = this.handleColorPicker.bind(this);
        this.handleCropDone = this.handleCropDone.bind(this);
        this.handleCropCancel = this.handleCropCancel.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleAdjust = this.handleAdjust.bind(this);
        this.handleCrop = this.handleCrop.bind(this);
        this.handleFlipImage = this.handleFlipImage.bind(this);
        this.handleGroup = this.handleGroup.bind(this);
        this.handleUngroup = this.handleUngroup.bind(this);
        this.handleSetAsBackground = this.handleSetAsBackground.bind(this);
        this.handleCopyElement = this.handleCopyElement.bind(this);
        this.handleLockElement = this.handleLockElement.bind(this);
        this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
        this.handleEditText = this.handleEditText.bind(this);

    }

    UNSAFE_componentWillReceiveProps(props) { // componentWillReceiveProps
        if (props.custom_fonts && props.custom_fonts.length >= 1) {
            this.setState({current_username: props.username});
            this.font_family_options_arr_uploaded = props.custom_fonts;
        }
        if (props.filterMode) {
            this.setState({activefilter: props.filterMode});
        }
    }

    handleEditText() {
        if (this.props.activeElements && this.props.activeElements.length === 1) {
            this.props.setEditTextElement(this.props.activeElements[0]);
        }
    }

    handleFilter() {
        this.props.showSideBar();
        if (this.state.activefilter !== 'filter') {
            this.setState({activefilter: 'filter'});
            this.props.showImageFilter('filter');
            this.props.setFilterMode();
        } else {
            this.setState({activefilter: ''});
            this.props.showImageFilter('sidebar');
            this.props.setDefaultMode();
        }


    }

    handleAdjust() {
        this.props.showSideBar();
        if (this.state.activefilter !== 'adjust') {
            this.setState({activefilter: 'adjust'});
            this.props.setAdjustMode();
            this.props.showImageFilter('adjust');
        } else {
            this.setState({activefilter: ''});
            this.props.showImageFilter('sidebar');
            this.props.setDefaultMode();
        }
    }

    handleCropDone() {
        this.setState({activefilter: 'crop'});
        this.props.setActiveElementProperty(this.props.imageCropData);
        this.props.setDefaultMode();
        this.props.clearActiveElements();
        this.props.saveHistory();
    }

    handleSetAsBackground() {
        if (!this.props.activeElements || this.props.activeElements.length === 0) return;
        this.props.setElementAsBackground(this.props.activeElements[0].hash);
        this.props.clearActiveElements();
    }

    handleCropCancel() {
        this.props.setDefaultMode();
        this.props.clearActiveElements();
    }

    handleCrop() {
        this.props.showImageFilter('crop');
        this.props.setCropMode();
    }

    handleColorPicker(id, property, color) {
        this.props.showSideBar();
        if (this.state.activefilter !== 'colorPicker') {
            this.setState({activefilter: 'colorPicker'});
            this.props.setColorPickerMode();
            this.props.setColorProperty(property, color);
            this.props.showImageFilter('colorPicker');
        } else {
            this.setState({colorPicker: ''});
            this.props.showImageFilter('sidebar');
            this.props.setDefaultMode();
        }
    }

    handleSVGColorPicker(originalColor, id, property, color) {
        this.props.showSideBar();
        if (this.state.activefilter !== 'colorPicker') {
            this.setState({activefilter: 'colorPicker'});
            this.props.setColorPickerMode();
            this.props.setColorProperty(property, color, originalColor);
            this.props.showImageFilter('colorPicker');
        } else {
            this.setState({colorPicker: ''});
            this.props.showImageFilter('sidebar');
            this.props.setDefaultMode();
        }
    }

    handleCopyElement() {
        this.props.copyElement();
    }

    handleLockElement() {
        this.props.setLockActiveElements(!this.props.locked);
    }

    handleFontList(val) {
        this.props.showFontList(val);
    }

    handleGroup() {
        this.handleUngroup();
        let groupElement = resolveGroupElement(this.props.activeElements);
        if(groupElement && groupElement.hash)
        {
            this.props.toggleActualGroup(groupElement.hash, true);
        }
    }

    handleUngroup() {
        let groupElement = resolveGroupElement(this.props.activeElements);
        if(groupElement && groupElement.hash)
        {
            this.props.cleanChildGroup(groupElement);
            this.props.toggleActualGroup(groupElement.hash, false);
        }
    }

    setActiveTextProperty(change) {
        let textElements = this.props.activeElements.filter((element) => element && (typeof element === "object") && !Array.isArray(element) && element.type === 'text');
        textElements.forEach(element => {
            this.props.setElementProperty(element.hash, change);
        })
    }

    setSVGColor(originalColor, changes) {
        let colorMap = this.props.activeElements[0].colorMap;
        if (!colorMap) {
            colorMap = {};
        }
        colorMap[originalColor] = changes.svgColor;
        let photoUrl = this.props.activeElements[0].svgSrcUrl;

        let properties = {
            colorMap: colorMap
        };
        if (photoUrl.indexOf('svg-proxy')>=0 && photoUrl.indexOf('url=')>=0) {
            photoUrl = decodeURIComponent(photoUrl.match(/url=([^&]*)/)[1]);
            properties['svgSrcUrl'] = photoUrl;
        }
        photoUrl = `${process.env.REACT_APP_IMAGE_PROXY_HOST}` + '/svg-proxy?url=' + encodeURIComponent(photoUrl) + '&colorMap=' + encodeURIComponent(JSON.stringify(colorMap));
        properties['photoUrl'] = photoUrl;
        this.props.setElementProperty(this.props.activeElements[0].hash, properties);
    }

    isFloat(x) {
        return !!(x % 1);
    }

    handleFlipImage(direction) {
        if (direction === 'horizontal') {
            this.props.setElementProperty(this.props.activeElements[0].hash, {flipX: !this.props.activeElements[0].flipX});
        } else {
            this.props.setElementProperty(this.props.activeElements[0].hash, {flipY: !this.props.activeElements[0].flipY});
        }
    }

    handleOnMouseDown() {
        if (this.props.editMode === 'text') {
            // handle deselect
            let groupElement = resolveGroupElement(this.props.activeElements);
            if (groupElement) {
                this.props.ungroupVirtualGroup();
            }
            this.props.applyTextForActiveElements();
            this.props.setTransformMode();
            this.props.saveHistory();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        for (let key in this.props) {
            if (key === 'activeElements') {
                if (JSON.stringify(this.props[key]) != JSON.stringify(nextProps[key])) {
                    return true;
                }
            } else {
                if (this.props[key] != nextProps[key]) {
                    return true;
                }
            }

        }
        for (let key in this.state) {
            if (this.state[key] != nextState[key]) {
                return true;
            }
        }
        return false;
    }

    render() {
        let leftGroupControls = [];
        let rightGroupControls = [];

        let selectedFonts = [];
        let fontList = this.font_family_options_arr_eng;

        if (this.props.pageList.length >= 1 && fontList && fontList.length >= 1) {
            this.props.pageList.forEach(idx => {
                idx.elements.forEach(element => {
                    if (element && (typeof element === "object") && !Array.isArray(element) && element.type === "text") {
                        let el;
                        this.font_family_options_arr_eng.map(function (x) {
                            if (x.value === element.textFontFamily && element.type === "text") {
                                el = {value: element.textFontFamily, label: element.textFontFamily, img: x.img}
                            }
                            return x;
                        });
                        this.font_family_options_arr_other.map(function (x) {
                            if (x.value === element.textFontFamily && element.type === "text") {
                                el = {value: element.textFontFamily, label: element.textFontFamily, img: x.img}
                            }
                            return x;
                        });
                        this.font_family_options_arr_uploaded.map(function (brand_font) {
                            return brand_font.brand_font_list.map(function (x) {
                                if (x.value === element.textFontFamily && element.type === "text") {
                                    let font_lable = (element.textFontFamily.indexOf("_") !== -1) ? element.textFontFamily.split("_")[0] : element.textFontFamily;
                                    el = {value: element.textFontFamily, label: font_lable, img: x.img}
                                }
                                return x;
                            });
                        });
                        if (el) {
                            let indexOfStevie = selectedFonts.findIndex(i => i.value === el.value);
                            if (indexOfStevie === -1) {
                                selectedFonts.push(el);
                                //this.font_family_options_arr.unshift(el)
                            }
                        }
                    }
                });
            });
        }

        let fonts = this.state.selectedFonts;
        if (JSON.stringify(selectedFonts) == JSON.stringify(this.state.selectedFonts)) {
            fonts = selectedFonts;
        }

        let textElements = this.props.activeElements.filter((element) => element && (typeof element === "object") && !Array.isArray(element) && element.type === 'text');
        //let imageElements = this.props.activeElements.filter((element) => element && element.type === 'photo');

        if (textElements.length && textElements.length) {
            let textFontFamily = {};
            textElements.forEach(element => {
                textFontFamily[element.textFontFamily] = true;
            });
            textFontFamily = Object.keys(textFontFamily);
            if (!this.props.locked) {
                leftGroupControls.push((
                    <Popup id="textFontFamily" key="textFontFamily" activeFonts={fonts}
                           showList={this.props.showlist} handleFontList={this.handleFontList}
                           activeElements={textFontFamily.length > 1 ? 'Multiple Font' : textFontFamily[0]}
                           fonts={this.font_family_options_arr_eng} custom_fonts={this.font_family_options_arr_uploaded}
                           fonts_other={this.font_family_options_arr_other}
                           applyPropertyChange={this.setActiveTextProperty}/>));
            }

            let textFontSize = {};
            textElements.forEach(element => {
                let font_size = element.textFontSize;
                let num = this.isFloat(font_size);
                textFontSize[num ? Number(font_size).toFixed(1) : Number(font_size)] = true;
            });
            textFontSize = Object.keys(textFontSize);

            this.font_size_options_arr = [];
            let startSize = 100;
            let step = 50;
            if (this.props.maxHeight<500) {
                startSize = 20;
                step = Math.ceil((this.props.maxHeight - startSize) / 100) * 10;
            }
            if (this.props.maxHeight>750) {
                step = 100;
            }
            for (let i = 0; startSize + (i * step) <= this.props.maxHeight; i++) {
                this.font_size_options_arr.push({value: "" + (startSize + (i * step)), label: "" + (startSize + (i * step)) + "px"});
            }

            if (!this.props.locked) {
                leftGroupControls.push((<Popup id="textFontSize" key="textFontSize" showList={this.props.showlist}
                                               handleFontList={this.handleFontList}
                                               activeElements={textFontSize.length > 1 ? textFontSize[0] : textFontSize[0]}
                                               fonts={this.font_size_options_arr}
                                               applyPropertyChange={this.setActiveTextProperty}/>));
            }

            let textFontColor = {};
            textElements.forEach(element => {
                textFontColor[element.textColor] = true;
            });
            textFontColor = Object.keys(textFontColor);
            if (!this.props.locked) {
                leftGroupControls.push((
                    <TextColorPicker id="textColor" key="textColor" showList={this.props.showlist}
                                     handleFontList={this.handleColorPicker}
                                     textColor={textFontColor.length > 1 ? '#ffffff' : textFontColor[0]}
                                     applyPropertyChange={this.setActiveTextProperty}/>
                ))
            }

            let textItalic = false;
            textElements.forEach(element => {
                textItalic = textItalic || element.textItalic;
            });
            let textBold = false;
            textElements.forEach(element => {
                textBold = textBold || element.textBold;
            });
            /* if (false && !this.props.locked) {
                leftGroupControls.push((
                    <TextStyleControl key="textStyle" isBold={textBold} isItalic={textItalic}
                                      applyPropertyChange={this.setActiveTextProperty}/>
                ));
            } */

            let textAlignment = {};
            textElements.forEach(element => {
                textAlignment[element.textAlignment] = true;
            });
            textAlignment = Object.keys(textAlignment);
            if (!this.props.locked) {
                leftGroupControls.push((
                    <TextAlignmentPicker id="textAlgnment" key="textAlgnment" showList={this.props.showlist}
                                         handleFontList={this.handleFontList}
                                         alignment={textAlignment.length > 1 ? 'left' : textAlignment[0]}
                                         applyPropertyChange={this.setActiveTextProperty}/>
                ));
            }

            if (this.props.activeElements.length === 1) {
                let textLetterSpacing = {};
                textElements.forEach(element => {
                    textLetterSpacing[element.textLetterSpacing] = true;
                });
                textLetterSpacing = Object.keys(textLetterSpacing);

                let textLineHeight = {};
                textElements.forEach(element => {
                    textLineHeight[element.textLineHeightRatio] = true;
                });
                textLineHeight = Object.keys(textLineHeight);

                if (!this.props.locked) {
                    leftGroupControls.push((<SliderPicker id="textSpacing" key="textSpacing" name="letterSpacingControl"
                                                          textspacingvalue={textLetterSpacing && textLetterSpacing[0] ? textLetterSpacing[0] : 0}
                                                          lineheightvalue={textLineHeight && textLineHeight[0] !== "undefined" ? textLineHeight[0] : 1}
                                                          applyPropertyChange={this.setActiveTextProperty}
                                                          textspacingmin={0} textspacingmax={1} textspacingdefault={0}
                                                          lineheightmin={0.5} lineheightmax={3}
                                                          lineheightdefault={1}/>));
                    if (this.props.access_level >= 70) {
                        leftGroupControls.push((
                            <ConstraintPanel data={textElements[0]} id="constraintPanel" key="constraintPanel"
                                             name="constraintPanel"/>));
                    }
                }
            }

        }

        if (this.props.activeElements.length === 1) {
            if (this.props.editMode === 'crop') {
                leftGroupControls.push((
                    <Button handleClick={this.handleCropDone} id="crop_done_btn" key="crop_done_btn" text="Done"/>
                ));
                leftGroupControls.push((
                    <Button handleClick={this.handleCropCancel} id="crop_cancel_btn" key="crop_cancel_btn"
                            text="Cancel"/>
                ));
            } else if (this.props.activeElements[0].type === 'graphic' || this.props.activeElements[0].type === 'photo' || this.props.activeElements[0].type === 'background') {
                if (this.props.activeElements[0].svgColors && this.props.activeElements[0].svgColors.length <= 5) {
                    this.props.activeElements[0].svgColors.forEach((svgColor, index) => {
                        let targetColor = svgColor;
                        if (this.props.activeElements[0].colorMap && this.props.activeElements[0].colorMap[svgColor]) {
                            targetColor = this.props.activeElements[0].colorMap[svgColor];
                        }
                        if (!targetColor || targetColor == '') {
                            targetColor = '#000000';
                        }
                        if (!this.props.locked) {
                            leftGroupControls.push((<TextColorPicker id={"svgColor" + index} key={"svgColor" + index}
                                                                     showList={this.props.showlist}
                                                                     handleFontList={this.handleSVGColorPicker.bind(this, svgColor)}
                                                                     property={"svgColor"} textColor={targetColor}
                                                                     applyPropertyChange={this.setSVGColor.bind(this, svgColor)}/>));
                        }
                    })

                }
                if (this.props.activeElements[0].type === 'background') {
                    if (!this.props.locked) {
                        leftGroupControls.push((
                            <TextColorPicker id="textColor" key="textColor" showList={this.props.showlist}
                                             handleFontList={this.handleColorPicker} property={"backgroundColor"}
                                             textColor={this.props.activeElements[0].backgroundColor}
                                             applyPropertyChange={changes => this.props.setActiveElementProperty(changes)}/>));
                    }
                }

                if (this.props.activeElements[0].photoUrl) {
                    if (!this.props.locked) {
                        leftGroupControls.push((<Button handleClick={this.handleFilter} id="Filter" key="Filter"
                                                        text={this.props.current_lang === 'en' ? 'Filter' : '濾鏡'}
                                                        is_active={this.state.activefilter === "filter"}/>));
                        // leftGroupControls.push((<Button is_active={this.state.activefilter === "adjust" ? true : false} key="Adjust" handleClick={this.handleAdjust} text={this.props.current_lang === 'en' ? 'Adjust' : '調整'} />));
                        if (!this.props.activeElements[0].flipX && !this.props.activeElements[0].flipY) {
                            leftGroupControls.push((<Button key="Crop" handleClick={this.handleCrop}
                                                            text={this.props.current_lang === 'en' ? 'Crop' : '裁剪'}/>));
                        }
                        leftGroupControls.push(<FlipControl id="FlipControl" key="FlipControl"
                                                            showList={this.props.showlist}
                                                            handleFlipImage={this.handleFlipImage}
                                                            handleFontList={this.handleFontList}/>);
                        // leftGroupControls.push((<Button  key="Flip" handleClick={this.handleFlip.bind(this)} text={this.props.current_lang === 'en' ? 'Flip' : '翻轉'} />));
                        leftGroupControls.push((
                            <div id="palette_box" key="adjustControl" className="title_box"><img className="adjust_icon"
                                                                                                 src={adjust_icon}
                                                                                                 onClick={this.handleAdjust.bind(this)}
                                                                                                 id="Copy"/></div>));

                    }
                }
                if (this.props.access_level >= 70) {
                    leftGroupControls.push((
                        <ConstraintPanel data={this.props.activeElements[0]} id="constraintPanel" key="constraintPanel"
                                         name="constraintPanel"/>));
                }
            }
        }
        // console.log(this.props.activeElements);
        if (this.props.activeElements && this.props.activeElements.length > 0 && this.props.editMode !== 'crop') {
            let isBackground = (this.props.activeElements.length === 1 && this.props.activeElements[0].type === 'background');
            // let groupElement = resolveGroupElement(this.props.activeElements);
            let groupCount = 0;
            let groupedElements = [];
            let remainingElements = [];

            this.props.activeElements.forEach(element => {
                if (element && (typeof element === "object") && !Array.isArray(element) && element.type === 'group' && !element.isVirtual) {
                    groupCount++;
                    if (!element.isVirtual && element.elements) {
                        element.elements.forEach(groupedElement => {
                            groupedElements.push(groupedElement);
                        })
                    }
                }
            });
            this.props.activeElements.forEach(element => {
                if (element && element.type !== 'group' && element.type !== 'background') {
                    if (groupedElements.indexOf(element.hash) === -1) {
                        remainingElements.push(element);
                    }
                }
            });

            if (remainingElements.length > 1 || groupCount > 1) {
                if (!this.props.locked) {
                    rightGroupControls.push((
                        <Button className="element_control_btn header_fonts" handleClick={this.handleGroup}
                                id="group_btn" key="group_btn" text={this.props.current_lang === 'en' ? "Group" : '組合'}>
                        </Button>))
                }
            }
            if (groupCount >= 1) {
                if (!this.props.locked) {
                    rightGroupControls.push((
                        <Button className="element_control_btn header_fonts" handleClick={this.handleUngroup}
                                id="ungroup_btn" key="ungroup_btn"
                                text={this.props.current_lang === 'en' ? "Ungroup" : '分拆'}>
                        </Button>))
                }
            }
            // if (groupElement && groupCount == 1) {
            //     if (groupElement.isVirtual) {
            //         rightGroupControls.push((<Button className="element_control_btn header_fonts" handleClick={this.handleGroup.bind(this)} id="group_btn" key="group_btn" text="Group">
            //         </Button>))
            //     } else {
            //         rightGroupControls.push((<Button className="element_control_btn header_fonts" handleClick={this.handleUngroup.bind(this)} id="ungroup_btn" key="ungroup_btn" text="Ungroup">
            //         </Button>))
            //     }
            // }
            if (!isBackground && this.props.activeElements.length === 1 && (this.props.activeElements[0].type === 'graphic' || this.props.activeElements[0].type === 'photo')) {
                if (!this.props.locked) {
                    rightGroupControls.push(<Button handleClick={this.handleSetAsBackground} id="setAsBackground"
                                                    key="setAsBackground"
                                                    text={this.props.current_lang === 'en' ? "Set As Background" : '設定為背景'}/>);
                }
            }
            if (!isBackground) {
                rightGroupControls.push(<Button handleClick={this.handleCopyElement} id="Copy" key="Copy"
                                                text={this.props.current_lang === 'en' ? "Copy" : '複製'}/>);
                if (!this.props.locked) {
                    rightGroupControls.push(<PositionComponent id="positionComponent" key="positionComponent"
                                                               showList={this.props.showlist}
                                                               handleFontList={this.handleFontList}
                                                               handlePosition={this.props.handlePosition}
                                                               handleAlignment={this.props.alignElements}
                                                               elementIdx={this.props.elementIdx}
                                                               pageIdx={this.props.pageIdx}
                                                               elLength={this.props.elLength}/>)
                }
            }
            if (this.props.activeElements[0].photoUrl) {
                if (!this.props.locked) {
                    rightGroupControls.push(<Transparency id="transparencyComponent" key="transparencyComponent"
                                                          textOpacity={this.props.activeElements[0].opacity}
                                                          showList={this.props.showlist}
                                                          handleFontList={this.handleFontList}
                                                          applyPropertyChange={changes => this.props.setActiveElementProperty(changes)}/>)
                }
            }
            if (!isBackground) {
                rightGroupControls.push(<div id="copy_box" key="copy_box" className="title_box"><img
                    className="copy_icon" src={copy_icon} onClick={this.handleCopyElement.bind(this)} id="Copy"/>
                </div>);
            }
            rightGroupControls.push((<div id="lock_box" key="lock_box" className="title_box"><img
                className={(this.props.locked ? "lock_icon" : "unlock_icon")}
                src={(this.props.locked ? lock_icon : unlock_icon)} onClick={this.handleLockElement} id="lock"/></div>));

            /*if (!this.props.locked) {
            }*/
            rightGroupControls.push((<DeleteComponent is_locked={this.props.locked} key="deleteComponent"
                                                      handleDeleteElement={this.props.handleDeleteElement}/>))

        }

        let read_only_msg = "";
        if (this.props.permission === "read" && this.props.engineReady) {
            read_only_msg = (<div className="read_only_mode">This design is shared for read only. You cannot modify this
                design.</div>);
        }

        return (
            <div key="Control Panel" className="element_control_panel" onMouseDown={this.handleOnMouseDown}>
                {read_only_msg}
                <div>
                    {(textElements.length === 1) ? (<TextInputControl key={"text_input_" + textElements[0].hash}
                                                                      handleSubmit={this.props.handleTextSubmit}
                                                                      text={textElements[0].textContent}/>) : null}
                    <div className="header_outline header_outline_style">
                        <div className="header_group_outline left">
                            {leftGroupControls}
                        </div>
                        <div className="header_group_outline right">
                            {rightGroupControls}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    pageList: state.pages,
    maxHeight: state.pages.length ? state.pages[0].height : 0,
    activeElements: state.activeElements,
    activePage: state.pages[state.activePageIndex],
    editMode: state.editMode,
    filterMode: state.filterMode,
    imageCropData: state.imageCropData,
    pageIdx: state.pageIdx,
    elementIdx: state.elementIdx,
    elLength: state.elLength,
    showlist: state.showlist,
    permission: state.permission,
    engineReady: state.engineReady,
    username: state.username,
    custom_fonts: state.custom_fonts,
    brand_token: state.brand_token,
    custom_font_brand_token: state.custom_font_brand_token,
    load_project_fonts: state.load_project_fonts,
    current_lang: state.current_lang,
    locked: !!state.activeElements.find((activeElement) => activeElement && !!activeElement.locked),
    access_level: state.access_level,
});

const mapDispatchToProps = dispatch => ({
    setActiveElementProperty: (changes) => dispatch(setActiveElementProperty(changes)),
    setDefaultMode: () => dispatch(setDefaultMode()),
    clearActiveElements: () => dispatch(clearActiveElements()),
    setCropMode: () => dispatch(setCropMode()),
    setFilterMode: () => dispatch(setFilterMode()),
    setAdjustMode: () => dispatch(setAdjustMode()),
    setColorPickerMode: () => dispatch(setColorPickerMode()),
    copyElement: () => dispatch(copyElement()),
    // setActivePageProperty: (changes) => dispatch(setActivePageProperty(changes)),
    showFontList: (val) => dispatch(showFontList(val)),
    showImageFilter: (val) => dispatch(showImageFilter(val)),
    toggleActualGroup: (hash, isActual) => dispatch(toggleActualGroup(hash, isActual)),
    alignElements: (alignment) => dispatch(alignElements(alignment)),
    showSideBar: () => dispatch(showSideBar()),
    saveHistory: () => dispatch(saveHistory()),
    setElementProperty: (hash, change, applyFromUserChanges = true) => dispatch(setElementProperty(hash, change, applyFromUserChanges)),
    setColorProperty: (property, color, svgOriginalcolor) => dispatch(setColorProperty(property, color, svgOriginalcolor)),
    // getCustomFonts: (username, callback) => dispatch(getCustomFonts(username, callback)),
    setElementAsBackground: (hash) => dispatch(setElementAsBackground(hash)),
    cleanChildGroup: (groupElement) => dispatch(cleanChildGroup(groupElement)),
    setLockActiveElements: (shouldLock) => dispatch(setLockActiveElements(shouldLock)),
    ungroupVirtualGroup: () => dispatch(ungroupVirtualGroup()),
    applyTextForActiveElements: () => dispatch(applyTextForActiveElements()),
    setTransformMode: () => dispatch(setTransformMode()),
    setEditTextElement: (element) => dispatch(setEditTextElement(element)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ElementControlPanel);
